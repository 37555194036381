import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stakingDataLoading: true,
  stakeLoading: false,
  stakes: [],
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    getStakingInfo: (state) => {
      state.stakingDataLoading = true;
    },
    setStakingInfo: (state, action) => {
      state.stakingDataLoading = false;
      state.stakes = action.payload;
    },
    cancelStakingDataLoading: (state) => {
      state.stakingDataLoading = false;
    },
    sendStakeInfo: (state, action) => {
      state.stakeDataLoading = true;
    },
    addStakeAmount: (state, action) => {
      state.stakeDataLoading = true;
    },
    cancelStakeInfoLoading: (state) => {
      state.stakeLoading = false;
    },
    unStake: (state, action) => {
      state.stakingDataLoading = true;
    },
    restake: (state, action) => {
      state.stakingDataLoading = true;
    },
    changePassForMultipliers: (state, payload) => {
      state.stakingDataLoading = true
    },
    checkBuyWeekMultiplierStatus: (state, payload) => {},
    checkBuyMonthMultiplierStatus: (state, payload) => {},
  },
});

export const {
  getStakingInfo,
  setStakingInfo,
  unStake,
  addStakeAmount,
  cancelStakingDataLoading,
  sendStakeInfo,
  cancelStakeInfoLoading,
  restake,
  checkBuyWeekMultiplierStatus,
  checkBuyMonthMultiplierStatus,
  changePassForMultipliers,
} = stakingSlice.actions;

const stakingReducer = stakingSlice.reducer;

export default stakingReducer;
