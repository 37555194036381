import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  claimInviteLoading: false,
  referralActivityLoading: false,
  referralData: null,
  referralActivity: null,
  generalMissions: [],
  weeklyMissions: [],
};

export const earnSlice = createSlice({
  name: 'earn',
  initialState,
  reducers: {
    cancelEarnLoading: (state) => {
      state.loading = false;
    },
    claimInviteBonus: (state) => {
      state.claimInviteLoading = true;
    },
    setNewClaimInfo: (state,action) => {
      state.claimInviteLoading = false;
      state.referralData.lastCount = action.payload
    },
    cancelClaimInviteLoading: (state) => {
      state.claimInviteLoading = false;
    },
    getReferralActivity: (state) => {
      state.referralActivityLoading = true;
    },
    setReferralActivity: (state,action) => {
      state.referralActivityLoading = false;
      state.referralActivity = action.payload;
    },
    cancelReferralActivityLoading: (state) => {
      state.referralActivityLoading = false;
    },
    getReferralData: (state) => {
      state.loading = true;
    },
    setReferralData: (state, action) => {
      state.referralData = action.payload;
      state.loading = false;
    },
    setGeneralMissions: (state, action) => {
      state.generalMissions = action.payload;
    },
    addCheckStatusForGeneralMission: (state, action) => {
      const index = state.generalMissions.findIndex(mission => mission.id === action.payload);
      state.generalMissions[index] = { ...state.generalMissions[index], checkStatus: true };
    },
    changeCompleteMissionStatus: (state, action) => {
      state.generalMissions = state.generalMissions.map(mission => mission.id === action.payload ? {
        ...mission,
        isCompleted: true,
      } : mission);
    },
  },
});

export const {
  setNewClaimInfo,
  claimInviteBonus,
  cancelClaimInviteLoading,
  getReferralData,
  getReferralActivity,
  setReferralActivity,
  cancelReferralActivityLoading,
  setReferralData,
  setGeneralMissions,
  changeCompleteMissionStatus,
  cancelEarnLoading,
  addCheckStatusForGeneralMission
} = earnSlice.actions;

const earnReducer = earnSlice.reducer;

export default earnReducer;
