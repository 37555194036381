import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  tapsData: null,
  prizeData: null,
  userInfo: null,
  loading: false,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    getLeaderboardData: () => {},
    getLeaderboardPrizeData: () => {},
    getUserLeaderboardData: (state) => {
      state.loading = true;
    },
    clearLeaderboardAfterLvlUpgrade: (state,action) => {
      delete state.data[action.payload];
      delete state.data[action.payload-1];
      delete state.data[action.payload+1];
    },
    setUserLeaderboardData: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.userTapsInfo = action.payload.userTapsInfo;
      state.tapsData = action.payload.tapsData;
      state.data = action.payload.data;
      state.loading = false;
    },
    setLeaderboardPrizeData: (state, action) => {
      state.prizeData = { ...state.prizeData, ...action.payload }
    },
    setLeaderboardData: (state, action) => {
      state.data = { ...state.data, ...action.payload.data }
      state.tapsData = { ...state.tapsData, ...action.payload.tapsData }
    },
    cancelLeaderboardLoading: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLeaderboardPrizeData,
  setLeaderboardPrizeData,
  clearLeaderboardAfterLvlUpgrade,
  getUserLeaderboardData,
  setUserLeaderboardData,
  cancelLeaderboardLoading,
  getLeaderboardData,
  setLeaderboardData,
} = leaderboardSlice.actions;

const leaderboardReducer = leaderboardSlice.reducer;

export default leaderboardReducer;
