import { lazy, Suspense } from 'react';
import { ServiceModalName } from '../../../constants';
import { selectServiceModals } from '../../../store/features/serviceModalSlice';
import OfferWallModal from './ServiceModals/OfferWallModal/OfferWallModal';
import MaintenanceModal from './ServiceModals/MaintenanceModal/MaintenanceModal';
import ReactDOM from 'react-dom';

const TransactionProcessModal = lazy(() => import('./ServiceModals/TransactionProcessModal/TransactionProcessModal'));
const SuccessModal = lazy(() => import('./ServiceModals/SuccessModal/SuccessModal'));
const PaymentMethodModal = lazy(() => import('./ServiceModals/PaymentMethodModal/PaymentMethodModal'));
const AgoPaymentModal = lazy(() => import('./ServiceModals/AgoPaymentModal/AgoPaymentModal'));
const WarningModal = lazy(() => import('./ServiceModals/WarningModal/WarningModal'));
const DailyBonusModal = lazy(() => import('./ServiceModals/DailyBonusModal/DailyBonusModal'));
const FriendLevelUpBonusModal = lazy(() => import('./ServiceModals/FriendLevelUpBonusModal/FriendLevelUpBonusModal'));
const InviteBonusModal = lazy(() => import('./ServiceModals/InviteBonusModal/InviteBonusModal'));
const LevelInfoModal = lazy(() => import('./ServiceModals/LevelInfoModal/LevelInfoModal'));
const LeaderboardPrizesModal = lazy(() => import('./ServiceModals/LeaderboardPrizesModal/LeaderboardPrizesModal'));
const ComboModal = lazy(() => import('./ServiceModals/ComboModal/ComboModal'));
const ComboCompleteModal = lazy(() => import('./ServiceModals/ComboCompleteModal/ComboCompleteModal'));
const BoostModal = lazy(() => import('./ServiceModals/BoostModal/BoostModal'));
const BoostInfoModal = lazy(() => import('./ServiceModals/BoostInfoModal/BoostInfoModal'));
const SuperBoostInfoModal = lazy(() => import('./ServiceModals/SuperBoostInfoModal/SuperBoostInfoModal'));
const SuperBoostPurchaseModal = lazy(() => import('./ServiceModals/SuperBoostPurchaseModal/SuperBoostPurchaseModal'));
const LootBoxClaimModal = lazy(() => import('./ServiceModals/LootBoxClaimModal/LootBoxClaimModal'));

const ModalSuspense = ({ children }) => {
  return <Suspense fallback={<div />}>{children}</Suspense>;
};

export const ServiceModal = () => {
  const modals = selectServiceModals();
  let modalIndex = 100;
  const getModalComponentByName = (modalName) => {
    modalIndex += 2;
    switch (modalName) {
      case ServiceModalName.offerWallModal:
        return (
          <ModalSuspense key={modalName}>
            <OfferWallModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.transactionProcessModal:
        return (
          <ModalSuspense key={modalName}>
            <TransactionProcessModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.successModal:
        return (
          <ModalSuspense key={modalName}>
            <SuccessModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.paymentMethodModal:
        return (
          <ModalSuspense key={modalName}>
            <PaymentMethodModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.warningModal:
        return (
          <ModalSuspense key={modalName}>
            <WarningModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.agoPaymentModal:
        return (
          <ModalSuspense key={modalName}>
            <AgoPaymentModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.dailyBonusModal:
        return (
          <ModalSuspense key={modalName}>
            <DailyBonusModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.friendLevelUpBonusModal:
        return (
          <ModalSuspense key={modalName}>
            <FriendLevelUpBonusModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.inviteBonusModal:
        return (
          <ModalSuspense key={modalName}>
            <InviteBonusModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.levelInfo:
        return (
          <ModalSuspense key={modalName}>
            <LevelInfoModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.leaderboardPrizesModal:
        return (
          <ModalSuspense key={modalName}>
            <LeaderboardPrizesModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.comboModal:
        return (
          <ModalSuspense key={modalName}>
            <ComboModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.comboCompleteModal:
        return (
          <ModalSuspense key={modalName}>
            <ComboCompleteModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.boostModal:
        return (
          <ModalSuspense key={modalName}>
            <BoostModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.boostInfoModal:
        return (
          <ModalSuspense key={modalName}>
            <BoostInfoModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.superBoostInfoModal:
        return (
          <ModalSuspense key={modalName}>
            <SuperBoostInfoModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.superBoostPurchaseModal:
        return (
          <ModalSuspense key={modalName}>
            <SuperBoostPurchaseModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.lootBoxClaimModal:
        return (
          <ModalSuspense key={modalName}>
            <LootBoxClaimModal index={modalIndex} />
          </ModalSuspense>
        );
      case ServiceModalName.maintenanceModal:
        return (
          <ModalSuspense key={modalName}>
            <MaintenanceModal index={modalIndex} />
          </ModalSuspense>
        );

      default:
        return null;
    }
  };

  return ReactDOM.createPortal(Object.keys(modals).map(getModalComponentByName), document.getElementById('modal-root'),
  );
};
