import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tapPassesLoading: false,
  passTabLoading: false,
  tapPassInfo: null,
  silverPassInfo: null,
  passesInfo: null,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    getPassesInfo: (state) => {
      state.passTabLoading = true;
    },
    setPassesInfo: (state, action) => {
      state.passesInfo = action.payload;
      state.passTabLoading = false;
    },
    cancelPassesLoading: (state) => {
      state.passTabLoading = false;
    },
    getSilverPassInfo: (state) => {
      state.loading = true;
    },
    setSilverPassInfo: (state, action) => {
      state.silverPassInfo = action.payload;
      state.loading = false;
    },
    getTapPassesInfo: (state) => {
      state.tapPassesLoading = true;
    },
    setTapPassesInfo: (state, action) => {
      state.tapPassInfo = action.payload;
      state.tapPassesLoading = false;
    },
    cancelTapPassesLoading: (state) => {
      state.tapPassesLoading = false;
    },
    buyTapPass: (state) => {
      state.tapPassesLoading = true;
    },
    checkBuyTapPassStatus: (state) => {
      state.tapPassesLoading = true
    },
    checkBuyBronzePassStatus: () => {},
    checkBuySteelPassStatus: (state,action) => {},
    checkBuySilverPassStatus: (state,action) => {},
  },
});

export const {
  cancelPassesLoading,
  getPassesInfo,
  setPassesInfo,
  checkBuyTapPassStatus,
  checkBuyBronzePassStatus,
  checkBuySteelPassStatus,
  checkBuySilverPassStatus,
  cancelTapPassesLoading,
  setTapPassesInfo,
  getTapPassesInfo,
  buyTapPass,
  getSilverPassInfo,
  setSilverPassInfo
} = storeSlice.actions;

const storeReducer = storeSlice.reducer;

export default storeReducer;
