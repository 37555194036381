import { all } from 'redux-saga/effects';
import { userSagaWatcher } from './userSaga';
import { levelSagaWatcher } from './levelSaga';
import { referralSagaWatcher } from './earnSaga';
import { storeSagaWatcher } from './storeSaga';
import { gamesSagaWatcher } from './gamesSaga';
import { stakingSagaWatcher } from './stakingSaga';
import { leaderboardSagaWatcher } from './leaderboardSaga';

export default function* rootSaga() {
  yield all([
    leaderboardSagaWatcher(),
    stakingSagaWatcher(),
    userSagaWatcher(),
    levelSagaWatcher(),
    referralSagaWatcher(),
    storeSagaWatcher(),
    gamesSagaWatcher()
  ]);
}
