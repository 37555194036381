import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  addStakeAmount,
  cancelStakeInfoLoading,
  cancelStakingDataLoading, changePassForMultipliers,
  checkBuyMonthMultiplierStatus,
  checkBuyWeekMultiplierStatus,
  getStakingInfo,
  restake,
  sendStakeInfo,
  setStakingInfo,
  unStake,
} from '../store/features/stakingSlice';
import { getUserBalanceWithItems, setUserBalance } from '../store/features/userSlice';
import {
  multiplierMonthHash,
  multiplierMonthHashPrice,
  multiplierMonthInfoModalStatus,
  multiplierWeekHash,
  multiplierWeekHashPrice,
  multiplierWeekInfoModalStatus,
  ServiceModalName,
} from '../constants';
import { removeServiceModal } from '../store/features/serviceModalSlice';
import { sendGTMEvent, sendGTMPurchaseEvent } from '../utils';

function* getStakingInfoSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/active`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      yield put(setStakingInfo(data.active_stakes));
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakingDataLoading.type });
    }
  }
}

function* restakeSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/restake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type: payload,
        }),
      });
      const data = yield response.json();
      yield put(setUserBalance(data.balance));
      yield put(getStakingInfo());
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakeInfoLoading.type });
    }
  }
}

function* sendStakeInfoSaga({ payload: { amount, type } }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/stake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type,
          amount,
        }),
      });

      yield put(getUserBalanceWithItems());
      yield put(getStakingInfo());
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakeInfoLoading.type });
    }
  }
}

function* addStakeAmountSaga({ payload: { amount, type } }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/add-base`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type,
          amount,
        }),
      });

      const data = yield response.json();
      yield put(setUserBalance(data.balance));
      yield put(getStakingInfo());
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakeInfoLoading.type });
    }
  }
}

function* unStakeSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/unstake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type: payload,
        }),
      });
      yield put(getUserBalanceWithItems());
      yield put(getStakingInfo());
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakingDataLoading.type });
    }
  }
}

function* changePassForMultipliersSaga({ payload: { type, amount, item } }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/staking/pass-trade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type,
          pass_item: item,
          pass_amount: amount,
        }),
      });
      const data = yield response.json();
      yield put(setStakingInfo(data.active_stakes));
      yield put(getUserBalanceWithItems());
    } catch (e) {
      console.error(e);
      yield put({ type: cancelStakingDataLoading.type });
    }
  }
}

export function* checkBuyWeekMultiplierStatusSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(multiplierWeekHash);
  const hashPrice = localStorage.getItem(multiplierWeekHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const multiplierInfoModal = localStorage.getItem(multiplierWeekInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_staking_multiplier_week_for_${payload}`, 'button', 'click', 'Finish transaction');
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(multiplierWeekHash);
          localStorage.removeItem(multiplierWeekHashPrice);
          localStorage.removeItem(multiplierWeekInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          yield put(getStakingInfo());
          isCompleted = true;
        } else if (count > 10 || +multiplierInfoModal > 10) {
          count = 0;
          isCompleted = true;
          sendGTMEvent(`abort_buying_staking_multiplier_week_for_${payload}`, 'notification', 'show', 'Abort transaction');
          localStorage.removeItem(multiplierWeekHash);
          localStorage.removeItem(multiplierWeekHashPrice);
          localStorage.removeItem(multiplierWeekInfoModalStatus);
        } else {
          yield delay(payload === 'stars' ? 1000 : 9000);
          count++;
          if (payload === 'ton') {
            localStorage.setItem(multiplierWeekInfoModalStatus, (+multiplierInfoModal + 1).toString());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuyMonthMultiplierStatusSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(multiplierMonthHash);
  const hashPrice = localStorage.getItem(multiplierMonthHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const multiplierInfoModal = localStorage.getItem(multiplierMonthInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_staking_multiplier_month_for_${payload}`, 'button', 'click', 'Finish transaction');
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(multiplierMonthHash);
          localStorage.removeItem(multiplierMonthHashPrice);
          localStorage.removeItem(multiplierMonthInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          yield put(getStakingInfo());
          isCompleted = true;
        } else if (count > 10 || +multiplierInfoModal > 10) {
          count = 0;
          isCompleted = true;
          sendGTMEvent(`abort_buying_staking_multiplier_month_for_${payload}`, 'notification', 'show', 'Abort transaction');
          localStorage.removeItem(multiplierMonthHash);
          localStorage.removeItem(multiplierMonthHashPrice);
          localStorage.removeItem(multiplierMonthInfoModalStatus);
        } else {
          yield delay(payload === 'stars' ? 1000 : 9000);
          count++;
          if (payload === 'ton') {
            localStorage.setItem(multiplierMonthInfoModalStatus, (+multiplierInfoModal + 1).toString());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}


export function* stakingSagaWatcher() {
  yield takeLatest([restake.type], restakeSaga);
  yield takeLatest([getStakingInfo.type], getStakingInfoSaga);
  yield takeLatest([sendStakeInfo.type], sendStakeInfoSaga);
  yield takeLatest([addStakeAmount.type], addStakeAmountSaga);
  yield takeLatest([unStake.type], unStakeSaga);
  yield takeLatest([checkBuyWeekMultiplierStatus.type], checkBuyWeekMultiplierStatusSaga);
  yield takeLatest([checkBuyMonthMultiplierStatus.type], checkBuyMonthMultiplierStatusSaga);
  yield takeLatest([changePassForMultipliers.type], changePassForMultipliersSaga);
}
