export const rewardInfoModalStatus = 'REWARD_INFO_MODAL_STATUS';
export const tapPassInfoModalStatus = 'TAP_PASS_INFO_MODAL_STATUS';
export const multiplierWeekInfoModalStatus = 'MULTIPLIER_WEEK_INFO_MODAL_STATUS';
export const multiplierMonthInfoModalStatus = 'MULTIPLIER_MONTH_INFO_MODAL_STATUS';
export const levelInfoModalStatus = 'LEVEL_INFO_MODAL_STATUS';
export const silverPassInfoModalStatus = 'SILVER_PASS_INFO_MODAL_STATUS';
export const bronzePassInfoModalStatus = 'BRONZE_PASS_INFO_MODAL_STATUS';
export const steelPassInfoModalStatus = 'STEEL_PASS_INFO_MODAL_STATUS';

export const multiplierWeekHash = 'MULTIPLIER_WEEK_HASH';
export const multiplierMonthHash = 'MULTIPLIER_MONTH_HASH';
export const dailyRewardHash = 'DAILY_REWARD_HASH';
export const bronzePassHash = 'BRONZEPASS_HASH';
export const steelPassHash = 'STEELPASS_HASH';
export const tapPassHash = 'TAP_PASS_HASH';
export const levelHash = 'LEVEL_HASH';
export const silverPassHash = 'SILVER_PASS_HASH';
export const gameProductHash = 'GAME_PRODUCT_HASH';

export const multiplierWeekHashPrice = 'MULTIPLIER_WEEK_PRICE';
export const multiplierMonthHashPrice = 'MULTIPLIER_MONTH_PRICE';
export const dailyRewardHashPrice = 'DAILY_REWARD_PRICE';
export const tapPassHashPrice = 'TAP_PASS_HASH_PRICE';
export const bronzePassHashPrice = 'BRONZEPASS_HASH_PRICE';
export const steelPassHashPrice = 'STEELPASS_HASH_PRICE';
export const levelHashPrice = 'LEVEL_PRICE';
export const silverPassHashPrice = 'SILVER_PASS_HASH_PRICE';

export const additionalRewardInfoModal = 'ADDITIONAL_REWARD_INFO_MODAL'
