import s from './OfferWallModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addServiceModal, removeServiceModal } from '../../../../../store/features/serviceModalSlice';
import { ServiceModalName } from '../../../../../constants';
import { useLocation } from 'react-router-dom';
import IconButton from '../../../../buttons/IconButton/IconButton';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import offerBackground from '../../../../../assets/images/offer-wall-background.png';

const OfferWallModal = ({ index }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const levelData = useSelector(state => state.level.data);

  const handleClose = () => {
    dispatch(removeServiceModal(ServiceModalName.offerWallModal));
  };

  const handleOpenLeaderboardPrizeModal = () => {
    dispatch(removeServiceModal(ServiceModalName.offerWallModal));
    dispatch(addServiceModal({
      name: ServiceModalName.leaderboardPrizesModal,
      payload: { level: levelData?.currentLvl },
    }));
  };

  if (location.pathname.startsWith('/play')) {
    return null;
  }

  return (
    <div className={s.backdrop} style={{ zIndex: index }}>
      <div className={s.modalContainer}>
        <dialog
          className={s.modal}
          aria-modal="true"
          style={{ background: `url(${offerBackground}) center/contain no-repeat` }}
        >
          <div className={s.header}>
            <div className={s.close}>
              <IconButton icon={<CloseIcon />} onClick={handleClose} />
            </div>
          </div>
        <div className={s.btn} onClick={handleOpenLeaderboardPrizeModal}>
          Join Event!
        </div>
        </dialog>
      </div>
    </div>
  );
};

export default OfferWallModal;
