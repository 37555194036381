import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading:true,
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setGlobalLoader: (state, action) => {
     state.loading = action.payload
    },
  },
});

export const {
  setGlobalLoader,
} = initSlice.actions;

const initReducer = initSlice.reducer;

export default initReducer;
