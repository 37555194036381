import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState({
        textColor: 'black', // Стандартный цвет текста
        backgroundColor: 'white' // Стандартный цвет фона
    });

    const telegramWebApp = window.Telegram && window.Telegram.WebApp;

    useEffect(() => {
        if (telegramWebApp) {
            telegramWebApp.onEvent('themeChanged', (newTheme) => {
                setTheme({
                    textColor: newTheme?.bg_color === '#000000' ? 'white' : 'black',
                    backgroundColor: newTheme?.bg_color || 'white'
                });
            });

            telegramWebApp.ready();
            setTheme({
                textColor: telegramWebApp.themeParams?.bg_color === '#000000' ? 'white' : 'black',
                backgroundColor: telegramWebApp.themeParams?.bg_color || 'white'
            });
        }
    }, [telegramWebApp]);

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
