import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: true,
};

export const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    getLevelData: (state) => {
      state.loading = true;
    },
    upgradeLevel: (state) => {
      state.loading = true;
    },
    cancelLevelLoading: (state) => {
      state.loading = false;
    },
    setLevelData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    checkBuyLevelStatus: (state, action) => {
      if(action.payload === 'stars') {
        state.loading = true
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLevelData,
  cancelLevelLoading,
  getLevelData,
  upgradeLevel,
  checkBuyLevelStatus
} = levelSlice.actions;

const levelReducer = levelSlice.reducer;

export default levelReducer;
