import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  cancelLeaderboardLoading,
  getLeaderboardData, getLeaderboardPrizeData, getUserLeaderboardData,
  setLeaderboardData, setLeaderboardPrizeData,
  setUserLeaderboardData,
} from '../store/features/leaderboardSlice';

function* getUserLeaderboardInfoSaga() {
  const token = yield select((state) => state.user.token);
  const levelData = yield select((state) => state.level.data);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/leaderboard/50`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      const preparedData = {
        data: {[levelData?.currentLvl]: data.top},
        userInfo: data.player_rank,
        userTapsInfo: data.player_rank_taps,
        tapsData: {[levelData?.currentLvl]: data.top_taps},
      };
      yield put(setUserLeaderboardData(preparedData));
    } catch (e) {
      console.error(e);
      yield put({type: cancelLeaderboardLoading.type});
    }
  }
}

function* getLeaderboardInfoSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/leaderboard/level/${payload}/50`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();

      const preparedData = {
        data: {[payload]: data.top},
        tapsData: {[payload]: data.top_taps},
      };
      yield put(setLeaderboardData(preparedData));
    } catch (e) {
      console.error(e);
    }
  }
}

function* getLeaderboardPrizeInfoSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/leaderboard-reward/${payload}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      const preparedData = {[payload]: data};
      yield put(setLeaderboardPrizeData(preparedData));
    } catch (e) {
      console.error(e);
    }
  }
}

export function* leaderboardSagaWatcher() {
  yield takeLatest([getUserLeaderboardData.type], getUserLeaderboardInfoSaga);
  yield takeLatest([getLeaderboardData.type], getLeaderboardInfoSaga);
  yield takeLatest([getLeaderboardPrizeData.type], getLeaderboardPrizeInfoSaga);
}
