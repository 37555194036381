export const stakingType = {
  week: 'week',
  month: 'month'
}

export const stakingPercent = {
  week: 0.25,
  month: 0.5
}

export const maxWeekMultipliersAmount = 60
export const maxMonthMultipliersAmount = 40

export const steelPassWeekMultiplier = 2
export const bronzePassWeekMultiplier = 8
export const silverPassWeekMultiplier = 20
export const goldPassWeekMultiplier = 60

export const steelPassMonthMultiplier = 1
export const bronzePassMonthMultiplier = 4
export const silverPassMonthMultiplier = 10
export const goldPassMonthMultiplier = 30

