import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  cancelLevelLoading,
  checkBuyLevelStatus,
  getLevelData,
  setLevelData,
  upgradeLevel,
} from '../store/features/levelSlice';
import { getUserBalanceSaga } from './userSaga';
import {
  levelHash,
  levelHashPrice,
  levelInfoModalStatus,
  ServiceModalName,
} from '../constants';
import { sendGTMEvent, sendGTMPurchaseEvent } from '../utils';
import { removeServiceModal } from '../store/features/serviceModalSlice';
import { getDailyRewardInfo, getUserTaps } from '../store/features/userSlice';
import { clearLeaderboardAfterLvlUpgrade, getUserLeaderboardData } from '../store/features/leaderboardSlice';

export function* getLevelInfoSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/level`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();
      yield put(setLevelData({
        dailySum: data.daily_sum,
        currentLvl: data.lvl,
        dailyData: data.daily,
        tap: data.tap,
        taps: data.taps,
        nextLvl: data.next_lvl,
        nextLvlData: data.next_data,
        isUpgradeAvailable: data.upgrade_available,
        availableReason: data.available_reason,
        upgradePrice: data.upgrade_price,
        upgradeTonPrice: data.current_price,
        upgradeUSDPrice: data.price_usd,
        upgradeStarsPrice: data.price_stars,
        delayDays: data.delay_days,
      }));
    } catch (e) {
      console.error(e);
      yield put({ type: cancelLevelLoading.type });
    }
  }
}

function* upgradeUserLevelSaga() {
  const token = yield select((state) => state.user.token);
  const levelData = yield select((state) => state.level.data);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/upgrade-level`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();
      if (data?.success) {
        yield call(getLevelInfoSaga);
        yield call(getUserBalanceSaga);
        yield put(getUserTaps());
        yield put(getDailyRewardInfo());
        yield put(getDailyRewardInfo());
        yield put(clearLeaderboardAfterLvlUpgrade(levelData.currentLvl));
        yield put(getUserLeaderboardData());
      } else {
        alert(`You can't upgrade yet`);
        yield put({ type: cancelLevelLoading.type });
      }
    } catch (e) {
      console.error(e);
      yield put({ type: cancelLevelLoading.type });
    }
  }
}

export function* checkBuyLevelStatusSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(levelHash);
  const hashPrice = localStorage.getItem(levelHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const levelInfoModal = localStorage.getItem(levelInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_level_for_${payload}`, 'button', 'click', 'Finish transaction');
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(levelHash);
          localStorage.removeItem(levelHashPrice);
          localStorage.removeItem(levelInfoModalStatus);
          if(payload === 'stars'){
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          yield put(getLevelData());
          yield put(getDailyRewardInfo());
          isCompleted = true;
        } else if (count > 10 || +levelInfoModal > 10) {
          count = 0;
          isCompleted = true;
          sendGTMEvent(`abort_buying_level_for_${payload}`, 'notification', 'show', 'Abort transaction');
          localStorage.removeItem(levelHash);
          localStorage.removeItem(levelHashPrice);
          localStorage.removeItem(levelInfoModalStatus);
        } else {
          yield delay(payload === 'stars' ? 1000 : 9000);
          count++;
          if (payload === 'ton') {
            localStorage.setItem(levelInfoModalStatus, (+levelInfoModal + 1).toString());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* levelSagaWatcher() {
  yield takeLatest([checkBuyLevelStatus.type], checkBuyLevelStatusSaga);
  yield takeLatest([upgradeLevel.type], upgradeUserLevelSaga);
  yield takeLatest([getLevelData.type], getLevelInfoSaga);
}
