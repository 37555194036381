import s from './MaintenanceModal.module.css';

const MaintenanceModal = ({ index }) => {
  return (
    <div className={s.backdrop} style={{ zIndex: index }}>
      <dialog className={s.modal} aria-modal="true"/>
    </div>
  );
};

export default MaintenanceModal;
