export const ROUTES = {
  initial: '/',
  main: 'main',
  daily: 'daily',
  earn: 'earn',
  store: 'store',
  play: 'play',
  level: 'level',
  leaderboard: 'leaderboard',
  staking: 'staking',
  stake: 'stake',
  multipliers: 'multipliers',
  info: 'info',
  balance: 'balance',
  mintAgo: 'ago-mint',
  games: 'games',
};
