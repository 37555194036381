import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import userReducer from './features/userSlice';
import levelReducer from './features/levelSlice';
import earnReducer from './features/earnSlice';
import storeReducer from './features/storeSlice';
import initReducer from './features/initSlice';
import serviceModalReducer from './features/serviceModalSlice';
import gamesReducer from './features/gamesSlice';
import stakingReducer from './features/stakingSlice';
import leaderboardReducer from './features/leaderboardSlice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    staking: stakingReducer,
    user: userReducer,
    init: initReducer,
    level: levelReducer,
    leaderboard: leaderboardReducer,
    earn: earnReducer,
    store: storeReducer,
    serviceModal: serviceModalReducer,
    games: gamesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    })
      .concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const runSaga = sagaMiddleware.run;
