export const missionsTabs = [
  // 'PARTNER',
  // 'WEEKLY',
  'GENERAL'
];

export const earnPageTabs = [
  {name: 'Referral' },
  // 'Quests',
  {name:'Missions', notice: true}
];

export const storePageTabs = [
  {name:'Ago Pass'},
  // {name:'LOOTBOX'},
  {name:'Tap Pass'}
];

export const stakingPageTabs = [
  {name:'Week'},
  {name:'Month'}
];

export const leaderboardPageTabs = [
  {name:'Weekly Taps'},
  {name:'Rating'}
];
