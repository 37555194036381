export const sendGTMEvent = (eventName, eventCategory, eventAction, eventLabel = '', revenue) => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: eventName,
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  };
  if (revenue) {
    data.revenue = revenue;
  }
  window.dataLayer.push(data);
};

export const sendGTMPurchaseEvent = (id, value,currency) => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: 'purchase',
    ecommerce: {
      transaction_id: id,
      value,
      currency,
    }
  }
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push(data);
};

export const sendGTMCustomPurchaseEvent = (name,id, value,currency) => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: name,
    ecommerce: {
      transaction_id: id,
      value,
      currency,
    }
  }
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push(data);
};

export const sendCustomGTMEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

