import ReactDOM from 'react-dom';
import s from './GlobalLoaderModal.module.css';
import { ReactComponent as LoadingIcon } from '../../../assets/icons/loading.svg';

const GlobalLoaderModal = () => {
  return ReactDOM.createPortal(
    <div className={s.modal} role="dialog" aria-modal="true">
      <LoadingIcon/>
    </div>,
    document.getElementById('modal-root')
  );
};

export default GlobalLoaderModal;
