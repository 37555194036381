import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {};

export const serviceModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addServiceModal: (state, { payload: { name, payload } }) => {
      state[name] = payload;
    },
    removeServiceModal: (state, { payload }) => {
      if (payload in state) {
        delete state[payload];
      }
    },
  },
});

export const {
  addServiceModal,
  removeServiceModal,
} = serviceModalSlice.actions;

export const selectServiceModal = (modalName) => useSelector((state) => state?.serviceModal[modalName]);
export const selectServiceModals = () => useSelector((state) => state?.serviceModal);
export const selectHasServiceModal = () => useSelector((state) => !!Object.keys(state.serviceModal).length);

const serviceModalReducer = serviceModalSlice.reducer;
export default serviceModalReducer;
