import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  data: [],
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    getGamesList: (state) => {
      state.loading = true;
    },
    setGamesList: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    cancelGamesLoading: (state) => {
      state.loading = false;
    },
    sendGameStartInfo: (state) => {
    },
  },
});

export const {
  sendGameStartInfo,
  getGamesList,
  setGamesList,
  cancelGamesLoading,
} = gamesSlice.actions;

const gamesReducer = gamesSlice.reducer;

export default gamesReducer;
