import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  tapsLoading: true,
  rewardLoading: false,
  isBoostActive: false,
  token: null,
  user: null,
  isUserExist: null,
  reward: {},
  items: {},
  balance: null,
  taps: null,
  boosters: null,
  usedBoosters: null,
  country: null,
  comboData: null,
  comboLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    registerUser: () => {
    },
    getUserStatus: () => {
    },
    activateBoost: (state) => {
      state.isBoostActive = true;
    },
    getComboData: (state) => {
      state.comboLoading = true;
    },
    setComboData: (state, action) => {
      state.comboData = action.payload;
      state.comboLoading = false;
    },
    clearComboData: (state, action) => {
      state.comboData = null;
    },
    cancelComboLoading: (state) => {
      state.comboLoading = false;
    },
    deactivateBoost: (state) => {
      state.isBoostActive = false;
    },
    setUserCountry: (state, action) => {
      state.country = action.payload;
    },
    setUserStatus: (state, action) => {
      state.isUserExist = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setUserBalanceWithItems: (state, { payload: { balance, items } }) => {
      state.balance = balance;
      state.items = items;
      state.loading = false;
    },
    getUserBalanceWithItems: (state) => {
      state.loading = true;
    },
    setUserBalance: (state, action) => {
      state.balance = action.payload;
      state.loading = false;
    },
    getUserTaps: (state) => {
      state.tapsLoading = true;
    },
    setUserBoosters: (state) => {
      state.usedBoosters = state.usedBoosters + 1;
    },
    cancelTapsLoading: (state) => {
      state.tapsLoading = false;
    },
    cancelUserLoading: (state) => {
      state.loading = false;
    },
    saveUserWallet: (state, { payload }) => {
    },
    getDailyRewardInfo: (state) => {
      state.rewardLoading = true
    },
    setDailyRewardInfo: (state, { payload }) => {
      state.rewardLoading = false;
      state.reward = payload;
    },
    claimDailyReward: (state, action) => {
      state.rewardLoading = true;
    },
    cancelDailyRewardLoading: (state) => {
      state.rewardLoading = true;
    },
    recheckUserBalance: () => {
    },
    sendUserTaps: () => {
    },
    checkBuyRewardStatus: (state, payload) => {
    },
    clearUserBalance: (state) => {
      state.balance = null;
    },
    setUserTaps: (state, action) => {
      state.taps = action.payload.taps;
      state.boosters = action.payload.boosters;
      state.usedBoosters = action.payload.usedBoosters;
      state.tapsLoading = false;
    },
    increaseUserBalance: (state, action) => {
      if (state.taps > 0) {
        state.balance = state.balance + action.payload;
        const newTaps = state.taps - (state.isBoostActive ? 5 : 1);
        state.taps = newTaps < 0 ? 0 : newTaps;
      }
    },
  },
});

export const {
  clearComboData,
  cancelComboLoading,
  getComboData,
  setComboData,
  setUserCountry,
  activateBoost,
  deactivateBoost,
  claimDailyReward,
  setUserBalance,
  setDailyRewardInfo,
  registerUser,
  cancelTapsLoading,
  saveUserWallet,
  getUserStatus,
  setUserStatus,
  setUserToken,
  setUserData,
  setUserBalanceWithItems,
  getUserBalanceWithItems,
  getUserTaps,
  recheckUserBalance,
  sendUserTaps,
  clearUserBalance,
  cancelUserLoading,
  increaseUserBalance,
  setUserTaps,
  setUserBoosters,
  getDailyRewardInfo,
  checkBuyRewardStatus,
  cancelDailyRewardLoading,
} = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
