import s from './IconButton.module.css';

function IconButton({
                      onClick,
                      isDisabled,
                      className,
                      icon,
                    }) {


  return (
    <button
      className={`${s.button} ${className} ${isDisabled && s.disabled}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {icon}
    </button>
  );
}

export default IconButton;
