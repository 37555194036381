import { call, put, select, takeLatest } from 'redux-saga/effects';
import { cancelGamesLoading, getGamesList, sendGameStartInfo, setGamesList } from '../store/features/gamesSlice';
import { cloudSetItem } from '../cloudStorage';

export function* getGamesListSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/games`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      yield cloudSetItem('gamesList', data, 3600 * 3);
      yield put(setGamesList(data));
    } catch (e) {
      console.error(e);
      yield put(setGamesList([]));
      yield put({ type: cancelGamesLoading.type });
    }
  }
}

export function* sendGameStartInfoSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/games/${payload}/sessions/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export function* gamesSagaWatcher() {
  yield takeLatest([getGamesList.type], getGamesListSaga);
  yield takeLatest([sendGameStartInfo.type], sendGameStartInfoSaga);
}
